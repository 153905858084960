import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import MileStone from '../../images/icon-milestone.png';
export const CardContainer = styled(Row)`
  margin: 20px;
  position: relative;
  @media (max-width: 767px) {
    margin: 0px;
  }
`;
export const IconDiv = styled.div`
  top: -5px;
  width: 67px;
  height: 100px;
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 50%;
  ::after {
    background: url(${MileStone}) no-repeat top left;
    background-size: 67px auto;
    content: '';
    display: block;
    position: absolute;
    top: 15px;
    left: 0;
    width: 67px;
    height: 67px;
    @media (max-width: 1399px) {
      top: 10px;
      left: 8px;
      width: 50px;
      height: 50px;
      background-size: 50px auto;
    }
    @media (max-width: 991px) {
      top: 15px;
      left: -10px;
      width: 50px;
      height: 50px;
      background-size: 50px auto;
    }
    @media (max-width: 767px) {
      width: 35px;
      height: 35px;
      background-size: 35px auto;
    }
  }
  @media (max-width: 1399px) {
    top: 15px;
    left: 0;
    width: 67px;
    height: 67px;
  }
  @media (max-width: 991px) {
    left: -50px;
    width: 50px;
    height: 82px;
    right: auto;
    margin: 0;
    top: -15px;
  }
  @media (max-width: 767px) {
    left: -10px;
    width: 35px;
    height: 65px;
    right: auto;
    margin: 0;
  }
`;
export const LeftCol = styled(Col)`
  padding: ${(props) => props.$padding};
  @media (max-width: 1399px) {
    padding: ${(props) => props.$paddingLg};
  }
  @media (max-width: 991px) {
    padding: 5px 5px 5px 20px;
  }
  @media (max-width: 767px) {
    padding: 5px;
  }
`;

export const RightCol = styled(Col)``;

export const GreyCard = styled.blockquote`
  padding: 40px 50px;
  border-radius: 20px;
  overflow: hidden;
  transition: 1s ease-out all;
  font-size: 20px;
  line-height: 30px;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  @media (max-width: 1399px) {
    padding: 20px;
  }
  @media (max-width: 767px) {
    padding: 20px;
  }
`;

export const ImageRight = styled.img`
  float: right;
  width: 170px;
  height: auto;
  margin: 3px 0 15px 30px;
  border: 0;
  vertical-align: top;
  @media (max-width: 991px) {
    float: right;
    width: 150px;
    height: auto;
    margin: 3px 0 15px 30px;
  }
  @media (max-width: 767px) {
    float: none;
    width: 100%;
    margin: 0 0 25px 0;
  }
`;

export const HeaderH3 = styled.h3`
  margin: 0 0 35px 0;
  font-family: 'Figtree', sans-serif;
  font-size: 28px;
  line-height: 35px;
  color: #55aa4c;
  @media (max-width: 991px) {
    font-size: 25px;
    line-height: 30px;
  }
`;
export const ParaStyle = styled.p`
  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 25px;
  }
`;

export const DateContainer = styled.div`
  text-align: ${(props) => props.$textAlign};
  padding: 30px 50px;
  font-family: 'Adelle-Bold', arial, sans-serif;
  font-size: 40px;
  line-height: 40px;
  color: #0e4c80;
  @media (max-width: 991px) {
    text-align: left;
    font-size: 30px;
    line-height: 30px;
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
  }
`;
